'use client';

import {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper/modules';
import 'swiper/css';
import CustomerItem from './CustomerItem/CustomerItem';
import customer from '../../data/customer';
import SwiperNav from '../SwiperNav/SwiperNav';

const Customer = () => {
  const [swiper, setSwiper] = useState(null);
  const handleContext = context => {
    setSwiper(context);
  };

  /*
    We need to duplicate customer review items here because there are just 3 right now. Due to this, swiping breaks because the slides per view exceeds half the total number of reviews: https://swiperjs.com/swiper-api#param-loop
    Stack overflow discussion: https://swiperjs.com/swiper-api#param-loop
  */
  const customerItems = [...customer, ...customer].map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <CustomerItem data={item} />
      </SwiperSlide>
    );
  });

  return (
    <section className='customer'>
      <div className='container'>
        <h3 className='subtitle'>Success stories</h3>
        <h2 className='title'>See what customers have to say about Cylerian</h2>
      </div>
      <div className='customer-slider-nav slider-nav'>
        <SwiperNav onPrevClick={() => swiper.slidePrev(300)} onNextClick={() => swiper.slideNext(300)} />
      </div>
      <div className='customer-slider-wrapper'>
        <div className='slider'>
          <Swiper id='customer-slider' modules={[Navigation]} spaceBetween={32} slidesPerView={3} centeredSlides={false} onSwiper={context => handleContext(context)} loop={true}>
            {customerItems}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Customer;

